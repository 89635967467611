import cx from 'classnames';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { Heading } from 'ui/components/1-atoms/Heading';
import { Container, ContainerWidth } from 'ui/components/4-habitats/Container';
import styles from './Embed.module.scss';
import { generateId } from 'helpers/id';

export interface EmbedProps {
	className?: string;
	title?: string;
	embedCode: string;
	embedSize?: ContainerWidth;
	disableAspectRatio?: boolean;
}

export const Embed: React.FC<EmbedProps> = ({ className, title, embedCode, disableAspectRatio, embedSize = 'std' }) => {
	const router = useRouter();
	const embedContainer = useRef<HTMLDivElement | null>(null);
	const scriptsContainer = useRef<HTMLDivElement | null>(null);
	const currentRoute = useRef(router.asPath);
	const isBrowserRefreshing = useRef(false);
	const manualLoadEventName = useRef(`EmbedManualLoad_${generateId()}`);
	const containsScripts = embedCode.includes('<script');

	const loadExternalScript = (script: HTMLScriptElement, container: HTMLElement): Promise<void> => {
		return new Promise((resolve) => {
			const newScript = document.createElement('script');
			newScript.src = script.src;
			newScript.async = true;
			newScript.onload = () => {
				if (script.onload) {
					script.onload.call(script); // Ensure proper `this` context
				}

				resolve();
			};
			newScript.onerror = () => {
				if (script.onerror) {
					script.onerror.call(script); // Ensure proper `this` context
				}

				resolve();
			};

			container.appendChild(newScript);
		});
	};

	useEffect(() => {
		// Detect browser refresh
		const handleBeforeUnload = () => {
			isBrowserRefreshing.current = true;
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		if (embedContainer.current) {
			const tempDiv = document.createElement('div');
			tempDiv.innerHTML = embedCode;
			const scripts = tempDiv.querySelectorAll('script');

			if (scripts.length === 0) {
				embedContainer.current.innerHTML = embedCode;

				// Clean up the temporary div
				tempDiv.remove();

				return;
			}

			const nonScriptContent = tempDiv.innerHTML.replace(/<script[\s\S]*?>[\s\S]*?<\/script>/gi, '');
			embedContainer.current.innerHTML = nonScriptContent;

			if (scriptsContainer.current) scriptsContainer.current.innerHTML = ''; // Clear previous scripts

			let shouldDispatchLoadEvent = false;
			const externalScriptsPromises: Promise<void>[] = [];

			scripts.forEach((script) => {
				if (script.src) {
					// Load external script and save the promise to wait for it
					const scriptPromise = loadExternalScript(script, scriptsContainer.current);
					externalScriptsPromises.push(scriptPromise);
				} else if (script.textContent) {
					// Inline script

					// Replace any load event listeners with a custom event as triggering the load event
					// manually will not work as expected with Next Router
					const regex = new RegExp(`window\\.addEventListener\\((['"])load\\1`, 'g');
					const updatedScriptContent = script.textContent.replace(
						regex,
						`window.addEventListener('${manualLoadEventName.current}'`,
					);

					// Check if the script listens for a load event
					if (
						!shouldDispatchLoadEvent &&
						updatedScriptContent.includes(`window.addEventListener('${manualLoadEventName.current}'`)
					) {
						shouldDispatchLoadEvent = true;
					}

					// Execute the inline script
					const runScript = new Function(updatedScriptContent);
					runScript();
				}
			});

			if (shouldDispatchLoadEvent) {
				// Wait for all external scripts to load before dispatching the custom load event
				Promise.all(externalScriptsPromises).then(() => {
					window.dispatchEvent(new Event(manualLoadEventName.current));
				});
			}

			// Clean up the temporary div
			tempDiv.remove();
		}

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [embedCode]);

	// Trigger a browser reload when the route changes to clean up any changes made by the embed
	useEffect(() => {
		const handleRouteChangeStart = (url: string) => {
			// Check if the route is changing and the browser is not just refreshing the page (e.g. F5).
			// If the route is changing, navigate to the new route with a full page reload.
			// This way we clean up any changes made by scripts in the embed.
			if (!containsScripts || isBrowserRefreshing.current) return;

			const newUrl = new URL(url, window.location.origin);

			if (currentRoute.current !== newUrl.pathname) {
				window.location.href = newUrl.href;
			}
		};

		router.events.on('routeChangeStart', handleRouteChangeStart);

		return () => {
			router.events.off('routeChangeStart', handleRouteChangeStart);
		};
	}, [router.events, containsScripts]);

	return (
		<Container section width={embedSize} spacingBottom={'sm'}>
			<div className={cx(styles.Embed, 'u-bottom-padding--md', className)}>
				{title?.length !== 0 && (
					<Heading headingLevel="h2" style="md" className={styles.Embed_title}>
						{title}
					</Heading>
				)}
				<div className={styles.Embed_content} />
				<div className={disableAspectRatio ? styles.Embed_content : styles.Embed_media}>
					<div ref={embedContainer} className={disableAspectRatio ? undefined : styles.Embed_mediabox} />
					<div ref={scriptsContainer} data-script-container></div>
				</div>
			</div>
		</Container>
	);
};
